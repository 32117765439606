<template>
  <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
          v-if="userPrepare"
          :general-data="user"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      user: 'users/getUser',
    }),
    userPrepare() {
      if (Object.keys(this.user).length > 0) {
        return true
      }
      return false
    },
  },
  data() {
    return {
      options: {},
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
    }),
  },
  async created() {
    await this.getUser(this.currentUser.id)
  },
}
</script>
