<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editUser">
      <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit" >
        <b-row>
          <b-col class="mb-2" >
            <ImageDropzone ref="images" :files="files" ></ImageDropzone>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" >
            <b-form-group
              label="Username"
              label-for="account-username"
            >
              <b-form-input
                v-model="user.surname"
                placeholder="Username"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Name"
              label-for="account-name"
            >
              <b-form-input
                v-model="user.name"
                name="name"
                placeholder="Name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
            >
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="user.email"
                  name="email"
                  placeholder="Email"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Phone"
              label-for="account-phone"
            >
              <b-form-input
                v-model="user.phone"
                name="phone"
                placeholder="Phone"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      files: [],
      user: this.generalData,
      profileFile: null,
      required,
      email,
    }
  },
  created() {
    let avatar = {
      name: 'Avatar',
      url: process.env.VUE_APP_IMAGE_PLACEHOLDER,
      urlDelete: `/users/delete_image/${this.generalData.id}`,
    }
    if (this.generalData.avatar !== null) {
      avatar.url = this.generalData.avatar
    }
    this.files = [avatar]
    this.user = this.generalData
  },
  methods: {
    ...mapActions({
      edit: 'users/edit',
    }),
    resetForm() {
      this.user = JSON.parse(JSON.stringify(this.generalData))
    },
    handleSubmit() {
      this.$refs.editUser.validate().then(success => {
        if (success) {
          const { id } = this.user
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, user: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.images.getFormData()

      data.append('name', this.user.name)
      data.append('email', this.user.email)
      data.append('phone', this.user.phone)
      data.append('surname', this.user.surname)
      data.append('lang', this.user.lang)

      return data
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
